import React, { useState, useEffect } from 'react';

import {
 
} from '@mui/material';

import {
  Box,
 
  TextField,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { Feedback, Send } from '@mui/icons-material';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import axios from 'axios';

import './Users.css';

const API_URL = 'https://sticker-backend-sigma.vercel.app';

const TOKEN = 'N3w$tr0ng#$%&#**FG@#$!@#$!2024';

const headers = {
  Authorization: `Bearer ${TOKEN}`,
  'Content-Type': 'application/json',
};

function FeedbackScreen() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newFeedback, setNewFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/feedback/getAllFeedback`, { headers });
      setFeedbacks(response.data);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
    } finally {
      setLoading(false);
    }
  };

  const submitFeedback = async () => {
    if (!newFeedback.trim()) return;
    setIsSubmitting(true);
    try {
      await axios.post(
        `${API_URL}/feedback/sendFeedback`,
        { content: newFeedback, device_id: '12345' },
        { headers }
      );
      setNewFeedback('');
      fetchFeedbacks();
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Prepare data for the chart
  const feedbackChartData = feedbacks.map((fb, index) => ({
    name: `Feedback ${index + 1}`,
    length: fb.content.length,
  }));

  return (
    <div className="feedback-screen">
      <Box className="feedback-box">
        <Box className="feedback-header">
          <Feedback fontSize="large" style={{ color: '#2e7d32' }} />
          <Typography variant="h4">User Feedback</Typography>
        </Box>

        <Box className="feedback-input">
          <TextField
            label="Add your feedback"
            variant="outlined"
            value={newFeedback}
            onChange={(e) => setNewFeedback(e.target.value)}
            InputProps={{
              style: { backgroundColor: '#fff', borderRadius: 8 },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={submitFeedback}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : <Send />}
          </Button>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className="chart-container">
              <Typography variant="h6" gutterBottom>
                Feedback Length Chart
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={feedbackChartData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#c8e6c9" />
                  <XAxis dataKey="name" stroke="#2e7d32" />
                  <YAxis stroke="#2e7d32" />
                  <Tooltip />
                  <Bar dataKey="length" fill="#66bb6a" radius={[10, 10, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            <Box className="feedback-table">
      <Typography variant="h6" gutterBottom>
        All Feedback
      </Typography>
      <TableContainer component={Paper} elevation={3}>
        <Table aria-label="feedback table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Content</strong></TableCell>
              <TableCell align="center"><strong>Device ID</strong></TableCell>
              <TableCell align="center"><strong>Created At</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks.map((fb, index) => (
              <TableRow key={index}>
                <TableCell>{fb.content}</TableCell>
                <TableCell align="center">{fb.device_id}</TableCell>
                <TableCell align="center">
                  {new Date(fb.created_at).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
          </>
        )}
      </Box>
    </div>
  );
}

export default FeedbackScreen;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './Dashboard.css';

const API_URL = 'https://sticker-backend-sigma.vercel.app';
const TOKEN = 'N3w$tr0ng#$%&#**FG@#$!@#$!2024';

const headers = {
  Authorization: `Bearer ${TOKEN}`,
  'Content-Type': 'application/json',
};

export const getAllSubscriptions = async () => {
  return axios.get(`${API_URL}/premium/all`, { headers });
};

export const addSubscriptionPrice = async (data) => {
  return axios.post(`${API_URL}/subscriptionPrice/addSubscriptionPrices`, data, { headers });
};

export const getSubscriptionPrices = async () => {
  return axios.get(`${API_URL}/subscriptionPrice/getSubscriptionPrices`, { headers });
};

export const updateSubscriptionPrices = async (data) => {
  return axios.put(`${API_URL}/subscriptionPrice/updateSubscriptionPrices`, data, { headers });
};

const DashboardScreen = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [dailyTotals, setDailyTotals] = useState([]);
  const [subscriptionPrices, setSubscriptionPrices] = useState({});
  const [newPrices, setNewPrices] = useState({
    weekly_price: '',
    monthly_price: '',
    yearly_price: ''
  });
  

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const subscriptionsResponse = await getAllSubscriptions();
        setSubscriptions(subscriptionsResponse.data.subscriptions);
        setDailyTotals(subscriptionsResponse.data.daily_totals);

        const pricesResponse = await getSubscriptionPrices();
        setSubscriptionPrices(pricesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAllData();
  }, []);

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setNewPrices({ ...newPrices, [name]: value });
  };

  const handleUpdatePrices = async () => {
    try {
      await updateSubscriptionPrices(newPrices);
      const updatedPrices = await getSubscriptionPrices();
      setSubscriptionPrices(updatedPrices.data); // Update displayed prices after successful update
      alert('Subscription prices updated successfully');
    } catch (error) {
      console.error('Error updating prices:', error);
      alert('Failed to update subscription prices');
    }
  };

  return (
    <div className="app-container">
      
      <div className="content">
        
      <div className="card card-graph">
          <h2>Subscriptions</h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={dailyTotals} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#d4d4d4" />
              <XAxis dataKey="date" stroke="#ffffff" />
              <YAxis stroke="#ffffff" />
              <Tooltip contentStyle={{ backgroundColor: '#333', borderRadius: '5px', color: '#ffffff' }} />
              <Line type="monotone" dataKey="total_price" stroke="#82ca9d" strokeWidth={3} activeDot={{ r: 10 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="card">

          <h2>Subscription Data</h2>
          <table className="subscription-table">
            <thead>
              <tr>
                <th>Device Name</th>
                <th>Subscription Type</th>
                <th>Price</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub) => (
                <tr key={sub._id}>
                  <td>{sub.device_name}</td>
                  <td>{sub.subscription_type}</td>
                  <td>${sub.price}</td>
                  <td>{new Date(sub.start_date).toLocaleDateString()}</td>
                  <td>{new Date(sub.end_date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card card-prices">
        <h2>Update Subscription Prices</h2>
        <div className="price-update-form">
          <label>
            One Day Price:
            <input
              type="number"
              name="weekly_price"
              value={newPrices.weekly_price}
              onChange={handlePriceChange}
            />
          </label>
          <label>
            Monthly Price:
            <input
              type="number"
              name="monthly_price"
              value={newPrices.monthly_price}
              onChange={handlePriceChange}
            />
          </label>
          <label>
            Yearly Price:
            <input
              type="number"
              name="yearly_price"
              value={newPrices.yearly_price}
              onChange={handlePriceChange}
            />
          </label>
          <button className="button" onClick={handleUpdatePrices}>Update Prices</button>
        </div>
      </div>

        <div className="card card-prices">
          <h2>Subscription Prices</h2>
          <ul>
            <li>One Day Price: <span className="price">${subscriptionPrices.weekly_price}</span></li>
            <li>Monthly Price: <span className="price">${subscriptionPrices.monthly_price}</span></li>
            <li>Yearly Price: <span className="price">${subscriptionPrices.yearly_price}</span></li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default DashboardScreen;
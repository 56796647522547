import axios from "axios";

const api = axios.create({
  baseURL: "https://api.gostickers.us/api",
});

const setAuthToken = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

setAuthToken();

export const getUsers = async () => {
  try {
    const response = await api.get("/Users");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post("/Users/register", userData);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const updateUser = async (userId, updatedData) => {
  try {
    const response = await api.put(`/Users/${userId}`, updatedData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    await api.delete(`/Users/${userId}`);
  } catch (error) {
    throw error;
  }
};


export const getStickers = async () => {
  const response = await api.get("/stickers/");
  return response.data;
};

export const createSticker = async (stickerData) => {
  const formData = new FormData();
  formData.append("Name", stickerData.name);
  formData.append("IsPremium", stickerData.is_premium ? "true" : "false");
  formData.append("file", stickerData.imageFile);
  formData.append("CategoryId", stickerData.category_id);

  const response = await api.post("/stickers/upload/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const updateSticker = async (stickerData) => {
  try {
    const formData = new FormData();
    formData.append("Name", stickerData.name);
    formData.append("IsPremium", stickerData.is_premium);
    formData.append("CategoryId", stickerData.category_id);

    if (stickerData.imageFile) {
      formData.append("file", stickerData.imageFile);
    }

    const response = await api.put(`/stickers/${stickerData.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating sticker:", error);
    throw error;
  }
};

export const deleteSticker = async (id) => {
  const response = await api.delete(`/stickers/${id}`);
  return response.data;
};

export const getCategories = async () => {
  const response = await api.get("/categories/");
  return response.data;
};

export const createCategory = async (categoryData) => {
  const formData = new FormData();
  formData.append("name", categoryData.name);
  formData.append("IsPremium", false); 
  formData.append("file", categoryData.file);

  const response = await api.post("/categories/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const updateCategory = async (categoryData) => {
  const formData = new FormData();
  formData.append("name", categoryData.name);
  formData.append("IsPremium", false); 
  formData.append("file", categoryData.file);

  const response = await api.put(`/categories/${categoryData.id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const deleteCategory = async (id) => {
  const response = await api.delete(`/categories/${id}`);
  return response.data;
};

export const validateAdmin = async (credentials) => {
  try {
    const response = await api.post("/admin/login", credentials);
    return response.data;
  } catch (error) {
    throw new Error("Login failed");
  }
};
const API_URL = 'https://sticker-backend-sigma.vercel.app';
const TOKEN = 'N3w$tr0ng#$%&#**FG@#$!@#$!2024';

const headers = {
  Authorization: `Bearer ${TOKEN}`,
  'Content-Type': 'application/json',
};

export const getAllSubscriptions = async () => {
  return axios.get(`${API_URL}/premium/all`, { headers });
};

export const addSubscriptionPrice = async (data) => {
  return axios.post(`${API_URL}/subscriptionPrice/addSubscriptionPrices`, data, { headers });
};

export const getSubscriptionPrices = async () => {
  return axios.get(`${API_URL}/subscriptionPrice/getSubscriptionPrices`, { headers });
};

export const updateSubscriptionPrices = async (data) => {
  return axios.put(`${API_URL}/subscriptionPrice/updateSubscriptionPrices`, data, { headers });
};

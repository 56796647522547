import React, { createContext, useContext, useState } from "react";
import { validateAdmin } from "../services/api";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false); 

  const handleLogin = async (credentials) => {
    const { email, password } = credentials;
    setLoading(true); 
    try {
      const response = await validateAdmin({ email, password });
      if (response && response.access_token) {
        localStorage.setItem("access_token", response.access_token);
        setIsAuthenticated(true);
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      alert("Invalid credentials");
    } finally {
      setLoading(false); 
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, handleLogin, handleLogout, loading, setLoading }} 
    >
      {children}
    </AuthContext.Provider>
  );
};
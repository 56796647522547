import React, { useState, useEffect } from "react";
import {
  getStickers,
  createSticker,
  updateSticker,
  deleteSticker,
  getCategories,
} from "../services/api";
import {
  Button,
  Modal,
  Typography,
  TextField,
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useLoading } from "../context/LoadingContext"; 
import "./Stickers.css";

const Stickers = () => {
  const [stickers, setStickers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [stickerData, setStickerData] = useState({
    name: "",
    imageFile: null,
    is_premium: false,
    category_id: "",
  });
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsModal, setIsDetailsModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    all: true,
    premium: false,
    free: false,
  });
  const [filteredStickers, setFilteredStickers] = useState([]);
  const { setIsLoading } = useLoading(); 

  useEffect(() => {
    fetchStickers();
    fetchCategories();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchQuery, filterOptions, stickers]);

  const fetchStickers = async () => {
    setIsLoading(true); 
    try {
      const data = await getStickers();
      setStickers(data);
      setFilteredStickers(data);
    } catch (error) {
      console.error("Error fetching stickers:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => setSearchQuery(e.target.value);

  const handleFilterChange = (filter) => {
    setFilterOptions({
      all: filter === "all",
      premium: filter === "premium" ? !filterOptions.premium : false,
      free: filter === "free" ? !filterOptions.free : false,
    });
  };

  const applyFilters = () => {
    setIsLoading(true); 
    setTimeout(() => { 
      let filtered = stickers;
      if (searchQuery) {
        filtered = filtered.filter((sticker) =>
          sticker.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (filterOptions.premium) {
        filtered = filtered.filter((sticker) => sticker.is_premium);
      } else if (filterOptions.free) {
        filtered = filtered.filter((sticker) => !sticker.is_premium);
      }
      setFilteredStickers(filtered);
      setIsLoading(false); 
    }, 200); 
  };

  const handleOpenModal = (sticker = null, isDetails = false) => {
    setSelectedSticker(sticker);
    setStickerData(
      sticker
        ? {
            name: sticker.name,
            is_premium: sticker.is_premium,
            category_id: sticker.category_id,
          }
        : { name: "", is_premium: false, category_id: "", imageFile: null }
    );
    setPreviewUrl(sticker ? sticker.url : ""); 
    setIsModalOpen(!isDetails);
    setIsDetailsModal(isDetails);
  };

  const handleCloseModal = () => {
    setSelectedSticker(null);
    setIsModalOpen(false);
    setIsDetailsModal(false);
    setPreviewUrl("");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setStickerData((prevData) => ({ ...prevData, imageFile: file }));
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleSaveSticker = async () => {
    setIsLoading(true);
    try {
      const formData = { ...stickerData };
      if (selectedSticker) {
        await updateSticker({ ...formData, id: selectedSticker.id });
      } else {
        await createSticker(formData);
      }
      fetchStickers();
      handleCloseModal();
    } catch (error) {
      console.error("Error saving sticker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this sticker?"
    );
    if (confirmDelete) {
      setIsLoading(true);
      try {
        await deleteSticker(id);
        fetchStickers();
        alert("Sticker deleted successfully.");
      } catch (error) {
        console.error("Error deleting sticker:", error);
        alert("Failed to delete sticker. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="sticker-container">
      <h2>Manage Stickers</h2>
      <div className="controls">
        <Button
          variant="contained"
          className="add-sticker-button"
          onClick={() => handleOpenModal()}
        >
          + Add New Sticker
        </Button>

        <TextField
          label="Search by Name"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          className="search-bar"
        />

        <div className="filter-container">
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.all}
                onChange={() => handleFilterChange("all")}
              />
            }
            label="All"
            className="checkbox-label"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.premium}
                onChange={() => handleFilterChange("premium")}
              />
            }
            label="Premium"
            className="checkbox-label"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.free}
                onChange={() => handleFilterChange("free")}
              />
            }
            label="Free"
            className="checkbox-label"
          />
        </div>
      </div>

      <div className="table-wrapper">
        <table className="styled-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Premium</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredStickers.map((sticker) => (
              <tr key={sticker.id}>
                <td>{sticker.name}</td>
                <td>{sticker.is_premium ? "Premium" : "Free"}</td>
                <td>
                  {sticker.category_id && categories.length
                    ? categories.find((cat) => cat.id === sticker.category_id)
                        ?.name || "N/A"
                    : "N/A"}
                </td>
                <td className="actions">
                  <a
                    onClick={() => handleOpenModal(sticker, true)}
                    className="action-link"
                  >
                    Details
                  </a>
                  <a
                    onClick={() => handleOpenModal(sticker)}
                    className="action-link"
                  >
                    Edit
                  </a>
                  <a onClick={() => handleDelete(sticker.id)} className="delete">
                    Delete
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal open={isModalOpen || isDetailsModal} onClose={handleCloseModal}>
        <Paper className="modal-box">
          <Typography variant="h6" mb={2}>
            {isDetailsModal
              ? "Sticker Details"
              : selectedSticker
              ? "Edit Sticker"
              : "Add New Sticker"}
          </Typography>
          <TextField
            label="Name"
            fullWidth
            value={stickerData.name}
            onChange={(e) =>
              !isDetailsModal &&
              setStickerData({ ...stickerData, name: e.target.value })
            }
            margin="normal"
            disabled={isDetailsModal}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stickerData.is_premium}
                onChange={(e) =>
                  !isDetailsModal &&
                  setStickerData({
                    ...stickerData,
                    is_premium: e.target.checked,
                  })
                }
                disabled={isDetailsModal}
              />
            }
            label="Premium Sticker"
          />
          <Select
            value={stickerData.category_id}
            onChange={(e) =>
              !isDetailsModal &&
              setStickerData({ ...stickerData, category_id: e.target.value })
            }
            fullWidth
            disabled={isDetailsModal}
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
         
          {isDetailsModal && selectedSticker && (
            <Box
              component="img"
              src={selectedSticker.url}
              sx={{
                width: "100%",
                height: "150px",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          )}
          {!isDetailsModal && (
            <>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ marginTop: "10px" }}
              />
              {previewUrl && (
                <Box
                  component="img"
                  src={previewUrl}
                  sx={{
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                    marginTop: "10px",
                  }}
                />
              )}
            </>
          )}
          <div className="modal-actions">
            {!isDetailsModal && (
              <Button
                variant="contained"
                onClick={handleSaveSticker}
                className="save-button"
              >
                Save
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              className="close-button"
            >
              Close
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default Stickers;

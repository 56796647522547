import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardScreen from './pages/Dashboard';
import FeedbackScreen from './pages/Users';
import Stickers from './pages/Stickers';
import Categories from './pages/Categories';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
import { useAuth } from './context/AuthContext';

function App() {
  const { isAuthenticated, handleLogout } = useAuth();

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isAuthenticated && <Navbar onLogout={handleLogout} />}
      <main style={{ flex: 1, padding: '20px' }}>
        <Routes>
          {/* Root path ("/") should display NotFound for unauthenticated users */}
          <Route
            path="/"//Update
            element={isAuthenticated ? <Navigate to="/sticker-sup-dupr-admin" replace /> : <NotFound />}
          />

          {/* Login Route */}
          <Route
            path="/sticker-sup-dupr-admin/login"
            element={isAuthenticated ? <Navigate to="/sticker-sup-dupr-admin" replace /> : <Login />}
          />

          {/* Admin Panel Routes for authenticated users only */}
          {isAuthenticated ? (
            <>
              <Route path="/sticker-sup-dupr-admin" element={<DashboardScreen />} />
              <Route path="/sticker-sup-dupr-admin/feedback" element={<FeedbackScreen />} />
              <Route path="/sticker-sup-dupr-admin/stickers" element={<Stickers />} />
              <Route path="/sticker-sup-dupr-admin/categories" element={<Categories />} />
            </>
          ) : (
            // If unauthenticated, redirect all other routes to NotFound
            <Route path="*" element={<NotFound />} />
          )}
        </Routes>
      </main>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../services/api";
import {
  Button,
  Modal,
  Typography,
  TextField,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { useLoading } from "../context/LoadingContext"; 
import "./Categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryData, setCategoryData] = useState({
    name: "",
    file: null,
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsModal, setIsDetailsModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { setIsLoading } = useLoading(); 

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchQuery, categories]);

  const fetchCategories = async () => {
    setIsLoading(true); 
    try {
      const data = await getCategories();
      setCategories(data);
      setFilteredCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleSearch = (e) => setSearchQuery(e.target.value);

  const applyFilters = () => {
    setIsLoading(true); 
    setTimeout(() => {
      let filtered = categories;
      if (searchQuery) {
        filtered = filtered.filter((category) =>
          category.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      setFilteredCategories(filtered);
      setIsLoading(false); 
    }, 200); 
  };

  const handleOpenModal = (category = null, isDetails = false) => {
    setSelectedCategory(category);
    setCategoryData(
      category ? { name: category.name } : { name: "", file: null }
    );
    setPreviewUrl(category ? category.imageUrl : ""); 
    setIsModalOpen(!isDetails);
    setIsDetailsModal(isDetails);
  };

  const handleCloseModal = () => {
    setSelectedCategory(null);
    setIsModalOpen(false);
    setIsDetailsModal(false);
    setPreviewUrl("");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setCategoryData((prevData) => ({ ...prevData, file }));
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleSaveCategory = async () => {
    setIsLoading(true); 
    try {
      const formData = { ...categoryData };
      if (selectedCategory) {
        await updateCategory({ ...formData, id: selectedCategory.id });
      } else {
        await createCategory(formData);
      }
      fetchCategories();
      handleCloseModal();
    } catch (error) {
      console.error("Error saving category:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (confirmDelete) {
      setIsLoading(true); 
      try {
        await deleteCategory(id);
        fetchCategories();
        alert("Category deleted successfully.");
      } catch (error) {
        console.error("Error deleting category:", error);
        alert("Failed to delete category. Please try again.");
      } finally {
        setIsLoading(false); 
      }
    }
  };

  return (
    <div className="category-container">
      <h2>Manage Categories</h2>
      <div className="controls">
        <Button
          variant="contained"
          className="add-category-button"
          onClick={() => handleOpenModal()}
        >
          + Add New Category
        </Button>

        <TextField
          label="Search by Name"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          className="search-bar"
        />
      </div>

      {filteredCategories.length === 0 ? (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      ) : (
        <div className="table-wrapper">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map((category) => (
                <tr key={category.id}>
                  <td>{category.name}</td>
                  <td className="actions">
                    <a
                      onClick={() => handleOpenModal(category, true)}
                      className="action-link"
                    >
                      Details
                    </a>
                    <a
                      onClick={() => handleOpenModal(category)}
                      className="action-link"
                    >
                      Edit
                    </a>
                    <a
                      onClick={() => handleDelete(category.id)}
                      className="delete"
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal open={isModalOpen || isDetailsModal} onClose={handleCloseModal}>
        <Paper className="modal-box">
          <Typography variant="h6" mb={2}>
            {isDetailsModal
              ? "Category Details"
              : selectedCategory
              ? "Edit Category"
              : "Add New Category"}
          </Typography>
          <TextField
            label="Name"
            fullWidth
            value={categoryData.name}
            onChange={(e) =>
              !isDetailsModal &&
              setCategoryData({ ...categoryData, name: e.target.value })
            }
            margin="normal"
            disabled={isDetailsModal}
          />
          {isDetailsModal && selectedCategory && (
            <Box
              component="img"
              src={selectedCategory.imageUrl} 
              sx={{
                width: "100%",
                height: "150px",
                objectFit: "cover",
                marginTop: "10px",
              }}
            />
          )}
          {!isDetailsModal && (
            <>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ marginTop: "10px" }}
              />
              {previewUrl && (
                <Box
                  component="img"
                  src={previewUrl}
                  sx={{
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                    marginTop: "10px",
                  }}
                />
              )}
            </>
          )}
          <div className="modal-actions">
            {!isDetailsModal && (
              <Button
                variant="contained"
                onClick={handleSaveCategory}
                className="save-button"
              >
                Save
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              className="close-button"
            >
              Close
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default Categories;

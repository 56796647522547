import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Navbar = () => {
  const { handleLogout } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const drawerList = (
    <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={NavLink} to="/sticker-sup-dupr-admin" exact>
          <HomeIcon /> <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={NavLink} to="/sticker-sup-dupr-admin/users" exact>
          <PeopleIcon /> <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={NavLink} to="/sticker-sup-dupr-admin/stickers" exact>
          <EmojiEmotionsIcon /> <ListItemText primary="Stickers" />
        </ListItem>
        <ListItem button component={NavLink} to="/sticker-sup-dupr-admin/categories" exact>
          <CategoryIcon /> <ListItemText primary="Categories" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <LogoutIcon /> <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ background: 'linear-gradient(to right, #0B3D2E, #134E4A)' }}>
      <Toolbar>
        <WhatsAppIcon sx={{ marginRight: 1, color: '#E6F4F1' }} />
        <Typography variant="h5" sx={{ flexGrow: 1, fontWeight: 'bold', color: '#E6F4F1' }}>
          WhatsApp Sticker Admin
        </Typography>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
              {drawerList}
            </Drawer>
          </>
        ) : (
          <Box>
            <Button
  component={NavLink}
  to="/"
  exact
  startIcon={<HomeIcon />}
  sx={{
    marginRight: 2,
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#E6F4F1',
    '&.active': { color: '#68B88E' },
    '&:hover': { color: '#68B88E' },
  }}
>
  Dashboard
</Button>
 <Button
  component={NavLink}
  to="/sticker-sup-dupr-admin/feedback"
  exact
  startIcon={<PeopleIcon />}
  sx={{
    marginRight: 2,
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#E6F4F1',
    '&.active': { color: '#68B88E' },
    '&:hover': { color: '#68B88E' },
  }}
>
  Feedback
</Button>
<Button
  component={NavLink}
  to="/sticker-sup-dupr-admin/stickers"
  exact
  startIcon={<EmojiEmotionsIcon />}
  sx={{
    marginRight: 2,
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#E6F4F1',
    '&.active': { color: '#68B88E' },
    '&:hover': { color: '#68B88E' },
  }}
>
  Stickers
</Button>
<Button
  component={NavLink}
  to="/sticker-sup-dupr-admin/categories"
  exact
  startIcon={<CategoryIcon />}
  sx={{
    marginRight: 2,
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#E6F4F1',
    '&.active': { color: '#68B88E' },
    '&:hover': { color: '#68B88E' },
  }}
>
  Categories
</Button>
            <Button
              color="inherit"
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              sx={{
                fontSize: '1.1rem',
                textTransform: 'none',
                color: '#FF4F4F',
                '&:hover': { color: '#FF7979' },
              }}
            >
              Logout
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
